import React, { useEffect } from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import photo3 from "../assets/images/magdalena2.png"
import { FiMail } from "react-icons/fi"
import { FiPhone } from "react-icons/fi"
import { FiSmartphone } from "react-icons/fi"
import { FiMapPin } from "react-icons/fi"
import { FaFacebook } from "react-icons/fa"
import AOS from "aos"
import "aos/dist/aos.css"
import Seo from "../components/Seo"

const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  @media (max-width: 1240px) {
    flex-direction: column-reverse;
  }
`

const ContactPhotoWrapper = styled.div`
  max-width: 50%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1440px) {
    /* width: 30%; */
  }
`
const ContactPhoto = styled.img`
  transform: translateX(-15%);
  height: 100%;
  width: 100%;
  transition: 0.5s linear;
  @media (max-width: 1440px) {
    transform: translateX(-15%);
    transition: 0.5s linear;
  }
  @media (max-width: 1240px) {
    transform: translateX(-50%);
    transition: 0.5s linear;
  }
  @media (max-width: 400px) {
    transform: translate(-50%, 10%);
    transition: 0.5s linear;
    position: sticky;
    bottom: 0;
  }
`

const ContactTextWrapper = styled.div`
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1240px) {
    max-width: 100%;
  }
`

const ContactClinic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ContactClinicHours = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (max-width: 1240px) {
    display: none;
  }
`
const ContactClinicHoursMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  transform: translateX(-70%);
  text-align: center;
  width: 100vw;
  @media (min-width: 1240px) {
    display: none;
  }
`

const StyledH3 = styled.h3`
  font-size: 2rem;
  padding: 2rem 2rem;
  margin: 1rem 1rem;

  @media (max-width: 450px) {
    font-size: 1.2rem;
    padding: 2rem 0rem;
    margin: 2rem 0rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSpan = styled.span`
  padding: 0.5rem 1rem;
  @media (max-width: 450px) {
    padding: 0.5rem 0.5rem;
    text-align: center;
  }
`

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])
  return (
    <Layout>
      <Seo title="Kontakt" />
      <ContactWrapper>
        <ContactPhotoWrapper>
          <ContactPhoto
            src={photo3}
            alt="hygienist-magdalena"
            title="Higienistka Magdalena Romatowska"
          />
          <ContactClinicHoursMobile>
            <StyledH3 data-aos="fade-left" style={{ width: "100%" }}>
              Godziny otwarcia:
            </StyledH3>
            <StyledSpan data-aos="fade-left" style={{ width: "100%" }}>
              poniedziałek – piątek || 08:00-20:00
            </StyledSpan>
            <StyledSpan data-aos="fade-left" style={{ width: "100%" }}>
              sobota || 08:00-14:00
            </StyledSpan>
            <Wrapper>
              <a
                href="https://www.facebook.com/CSDentMed"
                title="Higienistka Magdalena Romatowska | CS DentMed Facebook"
              >
                <FaFacebook
                  style={{
                    fontSize: "2rem",
                    marginTop: "1rem",
                    color: "#cbb26a",
                  }}
                />
              </a>
            </Wrapper>
          </ContactClinicHoursMobile>
        </ContactPhotoWrapper>
        <ContactTextWrapper>
          <ContactClinic data-aos="zoom-in">
            <StyledH3>Umów się na wizytę !</StyledH3>
            <Wrapper>
              <StyledSpan>DENT-MED</StyledSpan>
            </Wrapper>
            <Wrapper>
              <FiMapPin style={{ color: "cbb26a" }} />
              <StyledSpan>Królowej Jadwigi 2, 05-120 Legionowo</StyledSpan>
            </Wrapper>
            <Wrapper>
              <FiPhone style={{ color: "cbb26a" }} />
              <StyledSpan type="phone">22 774 03 35</StyledSpan>
            </Wrapper>
            <Wrapper>
              <FiSmartphone style={{ color: "cbb26a" }} />
              <StyledSpan type="phone">+48 603 572 835</StyledSpan>
            </Wrapper>
            <Wrapper>
              <FiMail style={{ color: "cbb26a" }} />{" "}
              <StyledSpan type="email"> csdentmed@gmail.com</StyledSpan>
            </Wrapper>
          </ContactClinic>
          <ContactClinicHours data-aos="zoom-in-up">
            <StyledH3>Godziny otwarcia:</StyledH3>
            <StyledSpan>poniedziałek – piątek || 08:00-20:00</StyledSpan>
            <StyledSpan>sobota || 08:00-14:00</StyledSpan>
            <Wrapper>
              <a
                href="https://www.facebook.com/CSDentMed"
                title="Higienistka Magdalena Romatowska | CS DentMed Facebook"
              >
                <FaFacebook
                  style={{
                    fontSize: "2rem",
                    marginTop: "1rem",
                    color: "cbb26a",
                  }}
                />
              </a>
            </Wrapper>
          </ContactClinicHours>
          <iframe
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9738.743056795338!2d20.9283003!3d52.3942466!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91555d30e03b9297!2sDENT%20Dental%20Center-MED%20Olton%20Anna!5e0!3m2!1sen!2spl!4v1645725779706!5m2!1sen!2spl"
            width="600"
            height="450"
            // style={{ borderColor: "cbb26a", border: "2px" }}
            loading="lazy"
            title="map"
          ></iframe>
        </ContactTextWrapper>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact
